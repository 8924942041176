/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export interface Medicines {
  timestamp: Date | undefined;
  medicines: Medicine[];
}

export interface Medicine {
  status: string;
  info: string[];
  medicineInfo: MedicineInfo | undefined;
  prognosis: Prognosis | undefined;
  company: Company | undefined;
  published: Date | undefined;
  lastUpdated: Date | undefined;
  rowNumber: number;
  varunummer: string;
  npl: string;
}

export interface MedicineInfo {
  name: string;
  form: string;
  packaging: string;
  activeSubstance: string;
  ATC: string;
  strength: string;
}

export interface Prognosis {
  start: Date | undefined;
  end: Date | undefined;
}

export interface Company {
  companyName: string;
  phone: string;
  email: string;
}

function createBaseMedicines(): Medicines {
  return { timestamp: undefined, medicines: [] };
}

export const Medicines = {
  encode(message: Medicines, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.medicines) {
      Medicine.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Medicines {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicines();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.medicines.push(Medicine.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Medicines {
    return {
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      medicines: globalThis.Array.isArray(object?.medicines)
        ? object.medicines.map((e: any) => Medicine.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Medicines): unknown {
    const obj: any = {};
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.medicines?.length) {
      obj.medicines = message.medicines.map((e) => Medicine.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Medicines>, I>>(base?: I): Medicines {
    return Medicines.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Medicines>, I>>(object: I): Medicines {
    const message = createBaseMedicines();
    message.timestamp = object.timestamp ?? undefined;
    message.medicines = object.medicines?.map((e) => Medicine.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMedicine(): Medicine {
  return {
    status: "",
    info: [],
    medicineInfo: undefined,
    prognosis: undefined,
    company: undefined,
    published: undefined,
    lastUpdated: undefined,
    rowNumber: 0,
    varunummer: "",
    npl: "",
  };
}

export const Medicine = {
  encode(message: Medicine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    for (const v of message.info) {
      writer.uint32(26).string(v!);
    }
    if (message.medicineInfo !== undefined) {
      MedicineInfo.encode(message.medicineInfo, writer.uint32(34).fork()).ldelim();
    }
    if (message.prognosis !== undefined) {
      Prognosis.encode(message.prognosis, writer.uint32(42).fork()).ldelim();
    }
    if (message.company !== undefined) {
      Company.encode(message.company, writer.uint32(50).fork()).ldelim();
    }
    if (message.published !== undefined) {
      Timestamp.encode(toTimestamp(message.published), writer.uint32(58).fork()).ldelim();
    }
    if (message.lastUpdated !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdated), writer.uint32(66).fork()).ldelim();
    }
    if (message.rowNumber !== 0) {
      writer.uint32(72).int32(message.rowNumber);
    }
    if (message.varunummer !== "") {
      writer.uint32(82).string(message.varunummer);
    }
    if (message.npl !== "") {
      writer.uint32(90).string(message.npl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Medicine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.info.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.medicineInfo = MedicineInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.prognosis = Prognosis.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.company = Company.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.published = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastUpdated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.rowNumber = reader.int32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.varunummer = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.npl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Medicine {
    return {
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      info: globalThis.Array.isArray(object?.info) ? object.info.map((e: any) => globalThis.String(e)) : [],
      medicineInfo: isSet(object.medicineInfo) ? MedicineInfo.fromJSON(object.medicineInfo) : undefined,
      prognosis: isSet(object.prognosis) ? Prognosis.fromJSON(object.prognosis) : undefined,
      company: isSet(object.company) ? Company.fromJSON(object.company) : undefined,
      published: isSet(object.published) ? fromJsonTimestamp(object.published) : undefined,
      lastUpdated: isSet(object.lastUpdated) ? fromJsonTimestamp(object.lastUpdated) : undefined,
      rowNumber: isSet(object.rowNumber) ? globalThis.Number(object.rowNumber) : 0,
      varunummer: isSet(object.varunummer) ? globalThis.String(object.varunummer) : "",
      npl: isSet(object.npl) ? globalThis.String(object.npl) : "",
    };
  },

  toJSON(message: Medicine): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.info?.length) {
      obj.info = message.info;
    }
    if (message.medicineInfo !== undefined) {
      obj.medicineInfo = MedicineInfo.toJSON(message.medicineInfo);
    }
    if (message.prognosis !== undefined) {
      obj.prognosis = Prognosis.toJSON(message.prognosis);
    }
    if (message.company !== undefined) {
      obj.company = Company.toJSON(message.company);
    }
    if (message.published !== undefined) {
      obj.published = message.published.toISOString();
    }
    if (message.lastUpdated !== undefined) {
      obj.lastUpdated = message.lastUpdated.toISOString();
    }
    if (message.rowNumber !== 0) {
      obj.rowNumber = Math.round(message.rowNumber);
    }
    if (message.varunummer !== "") {
      obj.varunummer = message.varunummer;
    }
    if (message.npl !== "") {
      obj.npl = message.npl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Medicine>, I>>(base?: I): Medicine {
    return Medicine.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Medicine>, I>>(object: I): Medicine {
    const message = createBaseMedicine();
    message.status = object.status ?? "";
    message.info = object.info?.map((e) => e) || [];
    message.medicineInfo = (object.medicineInfo !== undefined && object.medicineInfo !== null)
      ? MedicineInfo.fromPartial(object.medicineInfo)
      : undefined;
    message.prognosis = (object.prognosis !== undefined && object.prognosis !== null)
      ? Prognosis.fromPartial(object.prognosis)
      : undefined;
    message.company = (object.company !== undefined && object.company !== null)
      ? Company.fromPartial(object.company)
      : undefined;
    message.published = object.published ?? undefined;
    message.lastUpdated = object.lastUpdated ?? undefined;
    message.rowNumber = object.rowNumber ?? 0;
    message.varunummer = object.varunummer ?? "";
    message.npl = object.npl ?? "";
    return message;
  },
};

function createBaseMedicineInfo(): MedicineInfo {
  return { name: "", form: "", packaging: "", activeSubstance: "", ATC: "", strength: "" };
}

export const MedicineInfo = {
  encode(message: MedicineInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.form !== "") {
      writer.uint32(18).string(message.form);
    }
    if (message.packaging !== "") {
      writer.uint32(26).string(message.packaging);
    }
    if (message.activeSubstance !== "") {
      writer.uint32(34).string(message.activeSubstance);
    }
    if (message.ATC !== "") {
      writer.uint32(42).string(message.ATC);
    }
    if (message.strength !== "") {
      writer.uint32(50).string(message.strength);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MedicineInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicineInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.form = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.packaging = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeSubstance = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ATC = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.strength = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MedicineInfo {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      form: isSet(object.form) ? globalThis.String(object.form) : "",
      packaging: isSet(object.packaging) ? globalThis.String(object.packaging) : "",
      activeSubstance: isSet(object.activeSubstance) ? globalThis.String(object.activeSubstance) : "",
      ATC: isSet(object.ATC) ? globalThis.String(object.ATC) : "",
      strength: isSet(object.strength) ? globalThis.String(object.strength) : "",
    };
  },

  toJSON(message: MedicineInfo): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.form !== "") {
      obj.form = message.form;
    }
    if (message.packaging !== "") {
      obj.packaging = message.packaging;
    }
    if (message.activeSubstance !== "") {
      obj.activeSubstance = message.activeSubstance;
    }
    if (message.ATC !== "") {
      obj.ATC = message.ATC;
    }
    if (message.strength !== "") {
      obj.strength = message.strength;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MedicineInfo>, I>>(base?: I): MedicineInfo {
    return MedicineInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MedicineInfo>, I>>(object: I): MedicineInfo {
    const message = createBaseMedicineInfo();
    message.name = object.name ?? "";
    message.form = object.form ?? "";
    message.packaging = object.packaging ?? "";
    message.activeSubstance = object.activeSubstance ?? "";
    message.ATC = object.ATC ?? "";
    message.strength = object.strength ?? "";
    return message;
  },
};

function createBasePrognosis(): Prognosis {
  return { start: undefined, end: undefined };
}

export const Prognosis = {
  encode(message: Prognosis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.start !== undefined) {
      Timestamp.encode(toTimestamp(message.start), writer.uint32(10).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Timestamp.encode(toTimestamp(message.end), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Prognosis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrognosis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.start = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.end = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Prognosis {
    return {
      start: isSet(object.start) ? fromJsonTimestamp(object.start) : undefined,
      end: isSet(object.end) ? fromJsonTimestamp(object.end) : undefined,
    };
  },

  toJSON(message: Prognosis): unknown {
    const obj: any = {};
    if (message.start !== undefined) {
      obj.start = message.start.toISOString();
    }
    if (message.end !== undefined) {
      obj.end = message.end.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Prognosis>, I>>(base?: I): Prognosis {
    return Prognosis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Prognosis>, I>>(object: I): Prognosis {
    const message = createBasePrognosis();
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    return message;
  },
};

function createBaseCompany(): Company {
  return { companyName: "", phone: "", email: "" };
}

export const Company = {
  encode(message: Company, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyName !== "") {
      writer.uint32(10).string(message.companyName);
    }
    if (message.phone !== "") {
      writer.uint32(18).string(message.phone);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Company {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompany();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.companyName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Company {
    return {
      companyName: isSet(object.companyName) ? globalThis.String(object.companyName) : "",
      phone: isSet(object.phone) ? globalThis.String(object.phone) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
    };
  },

  toJSON(message: Company): unknown {
    const obj: any = {};
    if (message.companyName !== "") {
      obj.companyName = message.companyName;
    }
    if (message.phone !== "") {
      obj.phone = message.phone;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Company>, I>>(base?: I): Company {
    return Company.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Company>, I>>(object: I): Company {
    const message = createBaseCompany();
    message.companyName = object.companyName ?? "";
    message.phone = object.phone ?? "";
    message.email = object.email ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
